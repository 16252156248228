import React from "react";

// css
import * as styles from "./BookPack.module.css";

const BookPackHeading = () => {
  return (
    <>
      <div className={styles.bookPackV2__cont}>
        <div className={styles.bookPackV2__heading__content}>
          <h3>Enroll today</h3>
        </div>
      </div>
    </>
  );
};

export default BookPackHeading;
